export const langs = [
  "de",
  "ru",
  "es",
  "pt",
  "fr",
  "ja",
  "it",
  "cs",
  "el",
  "fi",
  "hi",
  "hr",
  "hu",
  "id",
  "ko",
  "nb",
  "nl",
  "pl",
  "tr",
  "he",
  "ar",
  "zh"
];

export default langs;
