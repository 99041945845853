import React, { useEffect } from 'react'
import Layout from '../components/layout'
import { Jumbotron, Button } from 'reactstrap';
import { navigate } from "gatsby";
import { langs } from "../../utils/lang-list";
import Helmet from "react-helmet";
import SiteConfig from "../../config/SiteConfig.json";

const NotFoundPage = () => {
  useEffect(() => {
    if(window.location.pathname.length < 4){
      let path = window.location.pathname.replace('/')
      console.log('--- path', path)
      let result = langs.filter(word => word === path);
      console.log('--- ', result)
      if(!result.length){
        navigate("/"); // redirecting to home page
      }
    }
  }, []);
  return (
    <Layout searchHide={true}>
      <Helmet>
        <title>{SiteConfig.brand + ' '}| 404 - Not Found</title>
      </Helmet>
    <Jumbotron className='text-center' style={{background: 'none', paddingTop: "100px"}}>
        <h1 className="display-4 font-weight-bold" style={{fontSize: "80px"}}>404</h1>
        <h3 className='text-black-50 py-4'>OOPS, SORRY WE CAN'T FIND THAT PAGE!</h3>
        <p className='pb-4' style={{fontSize: "15px"}}>Something went wrong or the page doesn't exist anymore</p>
        <hr className="my-2 py-4" />
        <p className="lead">
          <Button color="primary"><a style={{color: "white", textDecoration: "none"}} href="/">Home Page</a></Button>
        </p>
      </Jumbotron>
  </Layout>
  )
}
  


export default NotFoundPage

